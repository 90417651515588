import React from "react";
import SearchResultPage from "../../../templates/search-results-map-template";

const OffPlanResultsSalesMap = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/off-plan-properties-map/for-sale/" 
                pcategorytype="new_developments" 
                ptype="sales" 
                ptypetag="for-sale" 
                pstatustype=""
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                completionstatus="off-plan"
                {...props}
            />
        </React.Fragment>
    );
};

export default OffPlanResultsSalesMap;